<template>
<div>
    <v-app-bar>
        <h3>User List</h3>
        <v-spacer/>
        <v-btn @click="newUser" color="warning">New User</v-btn>
    </v-app-bar>
    <v-text-field type="text" v-model="search" label="Search by keywords" clearable append-icon="mdi-magnify"/>
    <h3>Selected for target link email: {{target_list.length}}</h3>
    <v-switch v-model="release_saftey" :true-value="true" :false-value="false" label="Relase E-blast Lock"/>
    <v-btn color="error" :disabled="!release_saftey || target_list.length==0" @click="service.send('send.activation_link')">SEND BLAST</v-btn>
    <fieldset v-if="activation_results">
        <legend>E-blast Results</legend>
        <ul>
            <li v-for="(target, target_index) in target_list" :key="'target_email_'+target_index">
                USER_ID: {{target}} - results: {{resultsFor(target)}}
            </li>
        </ul>
    </fieldset>

    <template v-if="debug">
        <v-btn @click="execute_import">Import Users</v-btn>
        <v-textarea v-model="import_list" outlined label="Importer"/>
        <fieldset>
            <legend>Import Reader</legend>
        </fieldset>
    </template>

    <v-data-table :headers="headers" :items="user_records"
        :loading-text="'...loading...'"
        :hide-default-footer="false"
        :disable-pagination="false"
        :footer-props="{
            itemsPerPageOptions: [50,100,200,-1]
        }"
        :multi-sort="true"
        class="elevation-1 tracker_table"
        dense
        style="margin-top: 25px;"
        >

        <template v-slot:body="{ items }">
            <tbody>
            <template v-for="(item, item_index) in items">
                <tr :key="'user_'+item_index" :class="[{[$style.inactive]:item.active=='N'},{[$style.not_validated]:item.validated=='N'}]">
                    <template v-for="(column,index) in headers">
                        <td v-if="column.value=='activation_link'" :key="'td_'+index" style="font-size: 8pt;">
                            <span v-html="item[column.value]"/>
                            <br>
                            Emailed: {{email_logs[item.id] ? email_logs[item.id].timestamp : "NO"}}
                            <v-checkbox small v-model="target_list" style="color:black !important;" :value="item.id" :disabled="item.active=='N' || item.validated=='Y'">
                                <template v-slot:label>
                                    <span>Select</span>
                                </template>
                            </v-checkbox>
                        </td>
                        <td v-else @click="select_user(item_index, item)" :key="'td_'+index" v-html="item[column.value]"/>
                    </template>
                </tr>

                <!-- USER EDITOR -->
                <tr v-if="editing.record_index==item_index" :key="'user_form_'+item_index">
                    <td colspan="100%" style="padding: 5px;border-left: 3px orange solid;">
                        <v-switch v-model="editing.user.active" true-value="Y" false-value="N" label="Active"/>
                        <v-switch v-model="editing.user.validated" true-value="Y" false-value="N" label="Verified"/>
                        <UserForm :user="editing.user" :admin="true" @update="update_editing" :options="{show:['firstname','lastname','email','language','password']}"/>
                        <v-select
                            label="Program Access"
                            v-model="editing.user.programs"
                            :items="program_options"
                            @change="editing.user.programs.sort()"
                            multiple
                            chips
                            outlined
                        />
                        <v-app-bar color="white" flat>
                            <v-btn small color="error" @click="cancel">Close</v-btn>
                            <v-spacer/>
                            <v-btn small color="warning" @click="save">SAVE</v-btn>
                        </v-app-bar>
                    </td>
                </tr>
            </template>
            </tbody>
        </template>

    </v-data-table>

    <v-snackbar v-model="snackbar.display">
        <template v-if="typeof snackbar.message=='string'">
            {{snackbar.message}}
        </template>
        <ul v-else>
            <li v-for="(item, item_index) in snackbar.message" :key="'snackbar_message_'+item_index">
                {{item}}
            </li>
        </ul>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import UserForm from '@/components/Forms/UserForm.vue'

export default {
    components: {
        UserForm
    },
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        programs: {
            type: Array,
            required: true
        }
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){
            this.fetch = new function(){

                this.users = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_users';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let users = response.data[action][call].results;
                                for(let i=0; i<users.length; i++){
                                    users[i] = component.parseJSON(users[i]);
                                }
                                
                                component.users = users;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.email_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_activation_email_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }

            this.save = new function(){
                this.user = function(component, context, import_user){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'save_user';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                user: import_user ? import_user : component.editing.user
                            }).then(function(response){
                                let outcome = response.data[action][call];
                                context.error.dataHandler = null;
                                component.snackbar.display = true;
                                if(outcome.result===true){
                                    component.snackbar.message = 'SAVED';
                                    if(outcome.user_id){
                                        component.users[0].id = outcome.user_id;
                                    }
                                }else{
                                    component.snackbar.message = outcome.error;
                                }
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }

            this.send = new function(){

                this.activation_link = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'send_activation_link';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                target_list: component.target_list
                            }).then(function(response){
                                let outcome = response.data[action][call];
                                context.error.dataHandler = null;
                                component.activation_results = outcome.results;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

            }
        }
        component.dataHandler = dataHandler

        let machineConfig = {
            id: 'admin',
            context: {
                error: {
                dataHandler: null
                }
            },
            initial: 'idle',
            states: {
                idle: {
                    always: '#fetch.users'
                },
                ready: {
                    on: {
                        'save.user' : '#save.user',
                        'send.activation_link' : '#send.activation_link'
                    }
                },
                fetch: {
                    id: "fetch",
                    initial: 'idle',
                    states: {
                        idle: {},
                        users: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.fetch.users(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#fetch.logs',
                                onError: '#admin.error'
                            }
                        },
                        logs: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.fetch.email_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#admin.ready',
                                onError: '#admin.error'
                            }
                        }
                    }
                },
                save: {
                    id: "save",
                    initial: 'idle',
                    states: {
                        idle: {},
                        user: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.save.user(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#admin.ready',
                                onError: '#admin.error'
                            }
                        }
                    }
                },
                send: {
                    id: "send",
                    initial: 'idle',
                    states: {
                        idle: {},
                        activation_link: {
                            invoke: {
                                src: (context) => new Promise((resolve, reject)=>{
                                    component.release_saftey = false;
                                    dataHandler.send.activation_link(component, context).then(function(){
                                        resolve();
                                    },function(){
                                        reject();
                                    })
                                }),
                                onDone: '#admin.ready',
                                onError: '#admin.error'
                            }
                        }
                    }
                },
                error: {}
            }

        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();

    },
    data: function(){
        return {
            dataHandler: null,
            logs: null,
            users: null,
            service: null,
            state: null,
            context: null,
            search: null,
            editing: {
                user: null,
                record_index: null
            },
            snackbar: {
                display: false,
                message: null
            },
            release_saftey: false,
            target_list: [],
            activation_results: null,
            import_list: null
        }
    },
    methods: {
        select_user: function(record_index, item){
            let users = this.users;
            let record = null;
            for(let i=0; i<users.length; i++){
                record = users[i];
                if(record.id == item.id){
                    break;
                }
            }

            this.editing.record_index = record_index;
            this.editing.user = this.unlink(record);
            this.editing.user.programs = record.programs ? record.programs : [];
        },
        update_editing: function(data){
            this.editing.user = data;
        },
        cancel: function(){
            this.editing.record_index=null;
        },
        save: function(){
            let users = this.users;
            let editing = this.editing;
            let newRecord = this.unlink(editing.user);

            let record = null;
            for(let i=0; i<users.length; i++){
                record = users[i];
                if(record.id == newRecord.id){
                    break;
                }
            }
            for(let key in newRecord){
                record[key] = newRecord[key];
            }

            let self = this;
            self.service.send('save.user')
        },
        newUser: function(){
            let users = this.users;
            if(users[0].id){
                users.unshift(userTemplate());
            }
            this.select_user(0, users[0]);

            function userTemplate(){
                return {
                    firstname: null,
                    lastname: null,
                    programs: [],
                    active: 'N',
                    language: null,
                    specialty: null,
                    email: ''
                }
            }
        },
        resultsFor: function(user_id){
            let results = this.activation_results;
            if(results){
                for(let i=0; i<results.length; i++){
                    let record = results[i];
                    if(record.id==user_id){
                        return record.activation_link_result && record.activation_link_result['error']==null;
                    }
                }
            }
            return null;
        },
        execute_import: function(){
            let programs = [1,2];
            let save_user = this.dataHandler.save.user;
            let promises = [];
            let import_users = this.import_users ? this.import_users.records : [];
            for(let i=0; i<import_users.length; i++){
                let user = import_users[i];
                user.programs = [];
                user.language = 'en';
                for(let p=0; p<programs.length; p++){
                    let program = programs[p];
                    if(user[program]){user.programs.push(program)}
                    delete user[program];
                }

                let new_promise = save_user(this, this.context, user);
                promises.push(new_promise);
                
            }

            Promise.all(promises).then(function(){
                console.log('uhhh...ok?...maaaaabye')
            });
        }
    },
    computed: {
        headers: function(){
            let headers = [
                {text:'ID', value:'id'},
                {text:'First Name', value:'firstname'},
                {text:'Last Name', value:'lastname'},
                {text:'Type', value:'type'},
                {text:'Specialty', value:'specialty'},
                {text:'Email', value:'email'},
                {text:'Language', value:'language'},
                {text:'Program(s)', value:'programs'},
                {text:'Activation Link', value:'activation_link'}
            ];

            return headers;
        },
        user_records: function(){
            let records = [];
            let users = this.users;
            let search = this.search;

            if(users){
                for(let i=0; i<users.length; i++){
                    let user = this.unlink(users[i]);
                    let access = [];
                    if(user.programs){
                        for(let p=0; p<user.programs.length; p++){
                            let pid = user.programs[p];
                            let program = this.program_map[pid];
                            access.push(program.name.en ? program.name.en : '');
                        }
                    }
                    user.programs = access;
                    user.activation_link = "<a href='"+'/activate/'+user.id+'/'+user.validation_code+"' target='_blank'>Link</a>";
                    if(search && search.length>0){
                        let user_string = JSON.stringify(user);
                        if(user_string.toLowerCase().includes(search.toLowerCase())){    
                            records.push(user);
                        }
                    }else{
                        records.push(user);
                    }
                }
            }

            return records;
        },
        program_map: function(){
            let programs = this.programs;
            let map = {};
            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                map[program.id] = program;
            }

            return map;
        },
        program_options: function(){
            let programs = this.$store.getters.programs;
            let program_options = [];

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                program_options.push({
                    text: program.name.en,
                    value: program.id
                })
            }
            

            return program_options;
        },
        email_logs: function(){
            let logs = this.logs;
            let email_logs = {};

            if(logs){
                for(let i=0; i<logs.length; i++){
                    let parameters = logs[i].parameters;
                    email_logs[parameters.target] = {
                        sent_by: parameters.admin,
                        language: parameters.language,
                        sent_to: parameters.target_email,
                        success: parameters.result.error === null,
                        timestamp: logs[i].timestamp
                    }
                }
            }

            return email_logs;
        },
        import_users: function(){
            if(this.import_list){
                let records = [];
                let rows = this.import_list.match(/[^\r\n]+/g);
                let header = rows[0].split('\t');
                let map = {};

                for(let i=1; i<rows.length; i++){
                    let row = rows[i].split('\t');
                    let record = {};
                    for(let h=0; h<header.length; h++){
                        record[header[h]] = header[h]=='email' ? row[h].toLowerCase() : row[h];
                        if(header[h]=='email'){
                            record[header[h]] = record[header[h]].replace(/\s/g, '');
                        }
                    }
                    records.push(record);
                }


                return {
                    records: records,
                    header: header,
                    rows: rows,
                    map: map
                };
            }else{
                return null;
            }
        },        
    }
}
</script>

<style module>
.not_validated{
    color: darkgrey;
}
.inactive{
    background-color: whitesmoke;
    text-decoration: line-through;
    color: darkgrey;
}
</style>