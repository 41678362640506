<template>
  <div>
    <v-container fluid ma-0 pa-0 fill-height>
      <v-row no-gutters align="start">

        <v-col cols="12" md="2" :class="$style.navigation" ><!--:style="{'min-height':$height+'px'}"-->
          <div :class="$style.navigation_label">
            <str :index="'navigation > label'"/>
            <v-icon v-if="allow" @click="expand.navigation = !expand.navigation" :class="[$style.expander,{[$style.expander_collapse]:!expand.navigation}]">mdi-triangle</v-icon>
          </div>

            <div v-if="expand.navigation" @click="main_content='topics';tabs.admin=null;">
              <template v-for="(program, program_index) in my_programs">
                <div v-if="program.active=='Y'" @click="tabs.navigation=program_index;" :class="[$style.tab, {[$style.tab_selected]:program_index==tabs.navigation}]" :key="'program_tab_'+program_index">
                  {{program.name[language]}}
                </div>
              </template>
            </div>


          <div :class="$style.navigation_label" v-if="allow">
            Admin
            <v-icon @click="expand.admin = !expand.admin" :class="[$style.expander,{[$style.expander_collapse]:!expand.admin}]">mdi-triangle</v-icon>
          </div>

            <div v-if="expand.admin" @click="main_content='admin';tabs.navigation=null;">
                <div @click="tabs.admin='UserManagement'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='UserManagement'}]">
                  User Management
                </div>
                <div @click="tabs.admin='ContentManagement'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='ContentManagement'}]">
                  Content Management
                </div>
                <div @click="tabs.admin='DataDashboard'" :class="[$style.tab, {[$style.tab_selected]:tabs.admin=='DataDashboard'}]">
                  Data Dashboard
                </div>
            </div>


        </v-col>


        <v-col cols="12" md="10" :class="$style.resources"><!-- :style="{'height':$height+'px'}"-->
          <template v-if="program && main_content=='topics'">
            <Topics :program="program" :language="language"/>
            <EditorialBoard/>
          </template>
          
          <Admin v-if="main_content=='admin'" :debug="debug" :tab="tabs.admin" :programs="programs" :language="language"/>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Topics from '@/components/Topics/Topics.vue'
import EditorialBoard from '@/components/EditorialBoard/EditorialBoard.vue'
import Admin from '@/views/Admin.vue'

export default {
  props: {
    debug: {
      type: Boolean,
      required: false,
      default: false
    },
    language: {
      type: String,
      required: true
    },
    programs: {
      type: Array,
      required: true
    },
    my_programs: {
      type: Array,
      required: true
    }
  },
  components: {
    EditorialBoard,
    Topics,
    Admin
  },
  data: function(){
    return {
      tabs: {
        navigation: 0,
        admin: null
      },
      expand: {
        navigation: true,
        admin: false
      },
      main_content: 'topics'
    }
  },
  computed: {
    program: function(){
      return this.my_programs[this.tabs.navigation]
    },
    $height: function(){
      let header = document.getElementsByTagName('header')[0]
      return window.innerHeight - header ? header.offsetHeight : 150;
    },
    allow: function(){
      let allow_types = ['admin','staff'];
      let user = this.$store.getters.user;

      return this.in_array(user.type, allow_types)
    }
  }
}
</script>

<style module>
.navigation {
  padding-top: 15px !important;
  background-color: #E0E3E8;
  color: slategray;
}

.navigation_label{
  position: relative;
  width: 100%;
  padding-left: 15px;
}

.resources {
  padding-top: 15px !important;
  background-color: #F0F2F5;
}

.tab{
  width: 100%;
  min-height: 50px;
  padding: 5px;
  text-align: center;
  cursor: pointer;
  transition: all 0.25s;
}

.tab_selected{
  background-color: #1C75BC;
  color: white;
  font-weight: bold;
}

.expander{
  position: absolute !important; 
  right: 20px;
  top: 5px;
  transform: rotate(180deg);
  font-size: 1em !important;
}

.expander_collapse{
  transform: rotate(90deg);
}

@media only screen and (max-width: 800px){
    .tab{
        display: inline-block;
        width: 33.3%;
    }
	
}
</style>