<template>
<div>
    <UserForm :user="user" @update="update" @valid="validForm"/>
    <v-btn @click="save" :disabled="!valid">Save</v-btn>

    <v-snackbar v-model="snackbar.display">
        <str v-if="snackbar.message" :index="snackbar.message"/>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import UserForm from '@/components/Forms/UserForm.vue'
export default {
    props: {
        user: {
            type: Object,
            required: true
        }
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){

            this.save = new function(){

                this.profile = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'forms';
                        let call = 'update_profile';

                        if(component.sendRequest){                  
                            component.sendRequest({
                                action: action,
                                call: call,
                                profile: component.profile
                            }).then(function(response){
                                if(response.data[action][call].result===true){
                                    resolve()
                                }else{
                                    reject()
                                }
                            },function(){
                                reject()
                            })
                        }else{
                            setTimeout(function(){
                                if(context.error.result){
                                    resolve();
                                }else{
                                    reject()
                                }
                            },500)
                        }
                    })
                }                
            }

        }    

        let machineConfig = {
            id: 'app',
            context: {
                error: null
            },
            initial: 'idle',
            states: {
                idle: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            let profile = component.unlink(component.user);
                            let delete_these = ['type','programs']
                            for(let i=0; i<delete_these.length; i++){
                                delete profile[delete_these[i]];
                            }
                            profile.password = null;
                            component.profile = profile
                            resolve();
                        }),
                        onDone: {
                            target: 'ready'
                        }
                    },
                    on: {
                        'ready' : 'ready'
                    }
                },
                ready: {
                    on: {
                        'save' : 'save'
                    }
                },
                save: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            dataHandler.save.profile(component, context).then(function(){
                                component.snackbar.message = "edit_profile > message > saved";
                                component.snackbar.display = true;
                                resolve();
                            },function(){
                                component.snackbar.message = 'message > error';
                                component.snackbar.display = true;
                                reject();
                            })
                        }),
                        onDone: {
                            target: 'ready'
                        },
                        onError: {
                            target: 'ready'
                        }
                    }
                }
            }
        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();    

    },
    components: {
        UserForm
    },
    mounted: function(){


    },
    data: function(){
        return {
            profile: null,
            snackbar: {
                display: false,
                message: null
            },
            context: null,
            service: null,
            state: null,
            valid: false
        }
    },
    methods: {
        update: function(data){
            let profile = this.profile;
            for(let key in profile){
                profile[key] = data[key];
            }
        },
        validForm: function(data){
            this.valid = data;
        },
        save: function(){            
            this.service.send('save');
        }
    }
}
</script>

<style>

</style>