<template>
  <v-app>
    <v-app-bar
      v-if="$store.getters.user && ready"
      app
      color="white"
      dark
      flat
    >
      <div class="d-flex align-center" @click="$route.name!='Home' ? $router.push('/') : false;">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          :src="images.adhd_hub"
          transition="scale-transition"
          width="200"
          style="margin-left: 50px;cursor: pointer;"
        />
      </div>

      <v-spacer></v-spacer>

      <div class="d-flex align-center">
        <ContactIcon style="margin:15px;"/>

        <UserIcon :user="$store.getters.user" @logout="service.send('jwt.logout')" style="margin:15px;"/>
        
        <LanguageSelect style="width:120px;"/>
          
      </div>
    </v-app-bar>





    <v-main style="background-color:whitesmoke;">
      <fieldset v-if="debug">
        <legend>STATES</legend>
        <ul>
          <li>READY: {{ready}}</li>
          <li>CSRF: {{state.csrf}}</li>
          <li>JWT: {{state.jwt}}</li>
          <li>Library: {{state.fetch.library}}</li>
          <li>LOGIN: {{state.ui.login}}</li>
          <li>AUTHORIZED: {{authorized}}</li>
        </ul>
      </fieldset>

      <Login :debug="debug" :show="state.ui.login=='show'" @close="service.send('ui.login.hide')"/>
      <router-view v-if="authorized && ready" :debug="debug" :language="language" :programs="programs" :my_programs="my_programs"/>
    </v-main>

    
    <v-footer
      app
      color="white"
      dark
      height="50"
      style="color: black;"
    >
      <v-spacer/>
        Terms of Use | Privacy Policy
      <v-spacer/>
      <img style="height: 50px;" :src="images.neuroscience"/>
    </v-footer>
  </v-app>
</template>

<script>
import Vue from 'vue'
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import Login from '@/components/Login/Login.vue'
import ContactIcon from '@/components/UI/ContactIcon.vue'
import UserIcon from '@/components/UI/UserIcon.vue'
import LanguageSelect from '@/components/UI/LanguageSelect.vue'

import adhd_hub_en from '@/assets/logos/adhd_hub_en.png'
import adhd_hub_fr from '@/assets/logos/adhd_hub_fr.png'
import neuroscience from '@/assets/logos/neuroscience.png'

export default {
  name: 'App',
  components: {
    Login,
    ContactIcon,
    UserIcon,
    LanguageSelect
  },
  created: function(){
    let component = this;

    const csrf = new function(){
        
        this.request = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){
                  component.sendRequest({
                    action: 'csrf',
                    call: 'request'
                  }).then(function(response){
                      var token = response.data.csrf.request.token
                      component.$store.dispatch('csrf',token);
                      resolve();
                  },function(response){
                    reject(response)
                  })
                }else{
                  setTimeout(function(){
                      context.csrf.value = 'simulated-csrf';
                      resolve();
                  },500)
                }
            })
        }

        this.validate = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){                  
                  component.sendRequest({
                    action: 'csrf',
                    call: 'validate',                    
                  }).then(function(response){
                      if(response.data['csrf-check'].result===true){
                        resolve();
                      }else{
                        component.$store.dispatch('clear_csrf');
                        reject();
                      }
                  },function(){
                    reject()
                  })                  
                }else{
                  setTimeout(function(){
                      context.csrf.value = 'simulated-csrf-validated';
                      resolve();
                  },500)
                }
            })
        }
    }

    const jwt = new function(){
        
        this.validate = function(component, context){
            return new Promise((resolve, reject)=>{
                if(component.sendRequest){                  
                    if(component.$store.getters.jwt){
                        component.sendRequest({
                          action: 'login',
                          call: 'validate',                    
                        }).then(function(response){
                            if(response.data.login.validate.pass===true){
                              resolve();
                            }else{
                                component.$store.dispatch('clear_jwt').then(function(){
                                reject();
                              })
                            }
                        },function(){
                          reject()
                        })                  
                    }else{
                        reject()
                    }
                }else{
                  setTimeout(function(){
                      if(context.authenticate.result){
                          context.jwt.value = 'simulated-jwt-validated';
                          resolve();
                      }else{
                        context.jwt.value = null;
                          reject()
                      }
                  },500)
                }
            })
        }

        this.logout = function(component, context){
            return new Promise((resolve, reject)=>{
                let action = 'login';
                let call = 'logout';

                if(component.sendRequest){                  
                    component.sendRequest({
                        action: action,
                        call: call
                    }).then(function(){                        
                        resolve();
                    },function(response){
                        context.error = response
                        reject();
                    })
                }else{
                    setTimeout(function(){
                        if(context.authenticate.result){
                            resolve();
                        }else{
                            reject()
                        }
                    },500)
                }
            })
        }

    }

    const dataHandler = new function(){
        this.fetch = new function(){

            this.programs = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'program';
                    let call = 'fetch_programs';

                    if(component.sendRequest){
                        component.sendRequest({
                            action: action,
                            call: call
                        }).then(function(response){
                            let programs = response.data[action][call].results;
                            for(let i=0; i<programs.length; i++){
                              programs[i] = component.parseJSON(programs[i]);
                            }
                            
                            component.$store.dispatch('programs',programs);
                            context.error.dataHandler = null;
                            resolve();
                        },function(response){
                          context.error.dataHandler = response;
                          reject();
                        })
                    }else{
                        setTimeout(function(){
                            resolve()
                        },1000)
                    }
                })
            }
            
            this.library = function(component, context){
                return new Promise((resolve, reject)=>{
                    if(component.sendRequest){
                        component.sendRequest({
                            action: 'str',
                            call: 'library'
                        }).then(function(response){
                            context.error.dataHandler = null;
                            component.$store.dispatch('library',response.data.str.library);
                            resolve();
                        },function(response){
                          context.error.dataHandler = response;
                          reject();
                        })
                    }else{
                        setTimeout(function(){
                            resolve()
                        },1000)
                    }
                })
            }
        }
    }    

    let machineConfig = {
        id: 'app',
        context: {
          error: {
            app: null,
            csrf: null,
            jwt: null,
            dataHandler: null
          }
        },
        type: 'parallel',
        states: {
            app: {
              initial: 'idle',
              states: {
                idle: {},
                ready: {},
                fetch: {}
              }
            },
            ui: {
              id: 'ui',
              type: 'parallel',
              states: {
                login: {
                  id: 'login',
                  initial: 'hide',
                  states: {
                    show: {},
                    hide: {}
                  },
                  on: {
                    'ui.login.show':'#ui.login.show',
                    'ui.login.hide':'#ui.login.hide'
                  }
                }
              }
            },
            csrf: {
              id: 'csrf',
              initial: 'init',
              states: {
                init: {
                  invoke: {
                    src: () => new Promise((resolve, reject)=>{
                      if(component.cookie && component.cookie.get('csrf')){
                        var token = component.cookie.get('csrf')
                        component.$store.dispatch('csrf', token);
                        resolve();
                      }else{
                        reject();
                      }
                    }),
                    onDone: {
                      target: '#csrf.validate'
                    },
                    onError: {
                      target: '#csrf.request'
                    }
                  }                
                },
                request: {
                  invoke: {
                    src: (context) => new Promise((resolve, reject)=>{
                      csrf.request(component, context).then(function(){
                        resolve()
                      },function(){
                        reject()
                      })
                    }),
                    onDone: {
                      target: '#csrf.validate'
                    },
                    onError: {
                      target: '#csrf.init'
                    }                    
                  }
                },
                validate: {
                  invoke: {
                    src: (context) => new Promise((resolve, reject)=>{
                      csrf.validate(component, context).then(function(){
                        resolve()
                      },function(){
                        reject()
                      })
                    }),
                    onDone: {
                      target: ['#csrf.valid','#fetch.library.active','#fetch.programs.active']
                    },
                    onError: {
                      target: '#csrf.request'
                    }
                  }                     
                },
                valid: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      resolve();
                    }),
                    onDone: {
                      target: '#jwt.init'
                    }
                  }
                },
                error: {}
              },
              on: {
                'csrf.request' : '#csrf.request'
              }
            },
            session: {
              id: 'session',
              initial: 'idle',
              states: {
                idle: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.stop_session().then(function(){
                        resolve();
                      })
                    })
                  },
                  on: {
                    'session.start' : '#session.start'
                  }
                },
                start: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.start_session().then(function(){
                        resolve();
                      })
                    }),
                    onDone: {
                      target: '#session.active'
                    }
                  }
                },
                restart: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      component.restart_session().then(function(){
                        resolve();
                      })
                    }),
                    onDone: {
                      target: '#session.active'
                    }
                  }
                },
                active: {
                  on: {
                    'session.restart' : '#session.restart',
                    'session.stop' : '#session.idle',
                  }                  
                }
              }
            },
            jwt: {
              id: 'jwt',
              initial: 'idle',
              states: {
                idle: {},
                init: {
                    invoke: {
                      src: () => new Promise((resolve, reject)=>{
                        if(component.cookie && component.cookie.get('jwt')){
                          var token = component.cookie.get('jwt')
                          component.$store.dispatch('jwt',token);
                          resolve();
                        }else{
                          reject();
                        }
                      }),
                      onDone: {
                        target: '#jwt.validate'
                      },
                      onError: {
                        target: ['#jwt.idle']
                      }
                    }
                },
                validate: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            jwt.validate(component,context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: ['#jwt.valid','#ui.login.hide']
                        },
                        onError: {
                            target: ['#jwt.idle','#ui.login.show']
                        }
                    }
                },
                valid: {
                  invoke: {
                    src: () => new Promise((resolve)=>{
                      if(component.$store.getters.entryURL){
                        component.$router.push(component.$store.getters.entryURL.path);
                        component.$store.dispatch('entryURL',null);
                      }
                      resolve();
                    }),
                    onDone: {
                      target: '#session.start'
                    }
                  }
                },
                logout: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{     
                            component.$store.dispatch('clear_jwt');
                            jwt.logout(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: ['#jwt.idle','#session.idle']
                        }
                    }
                }                
              },
              on: {
                'jwt.init': '#jwt.init',
                'jwt.logout': '#jwt.logout'
              }
            },
            fetch: {
              id: 'fetch',
              type: 'parallel',
              states: {
                programs: {
                  initial: 'idle',
                  states: {
                    idle: {},
                    error: {},
                    active: {
                      invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                          dataHandler.fetch.programs(component, context).then(function(){
                            resolve();
                          },function(){
                            reject();
                          })
                        }),
                        onDone: {
                          target: '#fetch.programs.idle'
                        },
                        onError: {
                          target: '#fetch.programs.error'
                        }
                      }
                    }
                  }

                },
                library: {
                  initial: 'idle',
                  states: {
                    idle: {},
                    ready: {},
                    error: {},
                    active: {
                      invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                          dataHandler.fetch.library(component, context).then(function(){
                            resolve();
                          },function(){
                            reject();
                          })
                        }),
                        onDone: {
                          target: '#fetch.library.ready'
                        },
                        onError: {
                          target: '#fetch.library.error'
                        }
                      }
                    }
                  }

                }
              },
              on: {
                'fetch.programs':'#fetch.programs.active',
                'fetch.library':'#fetch.library.active'
              }
            }
        }

    }

    const machine = Machine(machineConfig,{
        guards: {
            allow_comms: function(context){
                return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
            },
            allow_login: function(context){
                return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
            },
            allow_logout: function(context){
                return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
            }
        }
    });


    this.service = interpret(machine)
    this.state = machine.initialState
    this.context = machine.context

    var self = this
    self.service.onTransition(state => {
        self.state = state.value;
        self.context = state.context
    }).start();

    Vue.prototype.$controller = this;
  },

  data: () => ({
    debug: false,
    popups: {
      login: false
    },
    service: null,
    state: null,
    context: null,
    session: null,
    config: {
      session_timeout: 2 * 60 * 60 * 1000
    },
    login_trigger_timeout: null
  }),

  methods: {
    start_session: function(){
      return new Promise((resolve)=>{
        let self = this;
        clearTimeout(self.session);
        self.session = setTimeout(function(){
          self.service.send('jwt.logout')
        },self.config.session_timeout);
        resolve();
      })
    },
    restart_session: function(){
      return this.start_session();
    },
    stop_session: function(){
      return new Promise((resolve)=>{
        clearTimeout(this.session);
        resolve();
      })
    },
    show_login: function(){
      let self = this;
      clearTimeout(self.login_trigger_timeout);
      if(!self.ready){
        self.login_trigger_timeout = setTimeout(function(){
          self.show_login();
        },800)
      }else{
          if(self.$route.name=='Login'){
            switch(this.state.jwt){
              case"valid":
                self.$router.push('/');          
              break;

              case"idle":
                self.service.send('ui.login.show');
              break;
            }
          }
      }
    }
  },

  computed: {
    ready: function(){
      return this.state && (this.state.csrf=='valid' && this.state.fetch.library=='ready' && (this.state.jwt=='idle' || this.state.jwt=='valid'));
    },
    jwt: function(){
      return this.$store.getters.jwt
    },
    language: function(){
      return this.$store.getters.language
    },
    images: function(){
      return {
        adhd_hub: this.language=='fr' ? adhd_hub_fr : adhd_hub_en,
        neuroscience: neuroscience
      }
    },
    programs: function(){
      return this.$store.getters.programs;
    },
    user: function(){
      return this.$store.getters.user;
    },
    my_programs: function(){
      let my_programs = [];
      let user = this.user;
      let programs = this.programs;
      if(user && user.programs && programs){
        for(let i=0; i<programs.length; i++){
          let program = programs[i];
          if(this.in_array(program.id, user.programs) || this.in_array(this.user.type,['staff','admin'])){
            my_programs.push(program);
          }
        }
      }

      return my_programs;
    },
    library: function(){
      return this.$store.getters.library;
    },
    authorized: function(){
      return (this.state && this.$route.meta && this.$route.meta.requiresAuth) ? this.state.jwt=='valid' : true;
    },
    path: function(){
      return this.$route.name;
    }
  },

  watch: {
    jwt: function(){
      if(this.jwt){
        this.service.send('jwt.init');
        }else{          
          this.$router.push('/login')
      }
    },
    ready: function(){
      this.show_login();
      // let self = this;
      // if(self.ready && self.$route.name=='Login'){
      //   switch(this.state.jwt){
      //     case"valid":
      //       self.$router.push('/');          
      //     break;

      //     case"idle":
      //       self.service.send('ui.login.show');
      //     break;
      //   }
      // }
    },
    authorized: function(){
      console.log('watch > authorized', this.authorized)
    },
    path: function(){
      if(this.path=='Login'){
        this.show_login();
      }
    }
  }
};
</script>

<style>
h3{
    color: #F49041;
}
</style>