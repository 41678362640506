<template>
<div>
<v-form ref="emailForm" v-model="validForm">
    
    <template v-if="state=='idle'">
        <v-text-field type='email' v-model="form.email" outlined :label="'Email'" disabled
            required 
            validate-on-change
            :rules="formRules().validEmail"
        />
        <v-btn small @click="service.send('change_email')">Change Email</v-btn>
    </template>

    <template v-else>
        <v-text-field type='email' v-model="new_email" outlined :disabled="state!='change_email'" :label="'New Email'"
            required 
            validate-on-change
            :rules="formRules().validEmail"
            />

        <v-btn style='margin-right: 15px;' v-if="!admin" :disabled="!validForm" small color="warning" @click="service.send('send_code')">Send Verification Code</v-btn> 

        <v-text-field v-if="!admin" type="text" v-model="verification_code" :disabled="!validForm" :label="'Verification Code'"/>
        <v-btn small v-if="!admin" :disabled="verification_code===null" @click="service.send('validate_code')">Verify Code</v-btn>

        <v-btn small v-if="admin" :disabled="!validForm" @click="update">Accept</v-btn>

        <hr>
        <v-btn small @click="service.send('idle')">Cancel</v-btn>         
    </template>
</v-form>

{{state}}

<v-snackbar v-model="snackbar.display">
    <str v-if="snackbar.message" :index="snackbar.message"/>
</v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    props: {
        email: {
            type: String,
            required: true
        },
        admin: {
            type: Boolean,
            required: true
        }
    },
    created: function(){
        let email = this.email;
        this.form.email = email;

        let component = this;

        const comms = new function(){

            this.send_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'forms';
                    let call = 'send_email_code';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            email: component.email,
                            new_email: component.new_email
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve();
                            }else{
                                reject(response.data[action][call].error);
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.validate_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'forms';
                    let call = 'validate_email_code';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            reset_code: component.verification_code,
                            email: component.$store.getters.user.email
                        }).then(function(response){
                            if(response.data[action][call].result===true){
                                resolve()
                            }else{
                                reject(response.data[action][call].error)
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

        }    

        let machineConfig = {
            id: 'app',
            context: {
                error: null
            },
            initial: 'idle',
            states: {
                idle: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            component.new_email = null;
                            component.verification_code = null;
                            resolve();
                        })
                    },
                    on: {
                        'change_email' : 'change_email'
                    }
                },
                change_email: {
                    on: {
                        'send_code' : 'send_code'
                    }
                },
                send_code: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            comms.send_code(component, context).then(function(){
                                component.snackbar.message = 'email_form > message > code_sent'
                                component.snackbar.display = true;
                                resolve();
                            },function(error){
                                component.snackbar.message = error;
                                component.snackbar.display = true;
                                reject();
                            })
                        }),
                        onDone: {
                            target: 'enter_code'
                        },
                        onError: {
                            target: 'enter_code'
                        }
                    }
                },
                enter_code: {
                    on: {
                        'validate_code' : 'validate_code'
                    }
                },
                validate_code: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            comms.validate_code(component, context).then(function(){
                                component.update();
                                resolve();
                            },function(error){
                                component.snackbar.message = error;
                                component.snackbar.display = true;
                                reject();
                            })
                        }),
                        onDone: {
                            target: 'change_email'
                        },
                        onError: {
                            target: 'enter_code'
                        }
                    }
                }
            },
            on: {
                'idle' : 'idle'
            }
        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();        
    },
    data: function(){
        return {
            validForm: false,
            service: null,
            state: null,
            context: null,
            verification_code: null,
            new_email: null,
            form: {
                email: null
            },
            snackbar: {
                display: false,
                message: null
            }
        }
    },
    methods: {
        update: function(){
            this.form.email = this.new_email;
            this.$emit('update',this.form.email);
            this.service.send('idle');
        }
    },
    watch: {
        state: function(){
            this.$emit('state_change',this.state);
        }
    }
}
</script>

<style>

</style>