<template>
<div>
    <ActivateAccount/>
</div>
</template>

<script>
import ActivateAccount from '@/components/ActivateAccount/ActivateAccount.vue'

export default {
    components: {
        ActivateAccount
    }
}
</script>

<style>

</style>