<template>
<div>
    <v-dialog v-model="display" persistent :hide-overlay="true" max-width="1024">
        <v-card>
            <v-card-title>
                <v-spacer/>
                    <h3 style="color:#1C75BC"><str :index="'login > title'"/></h3>
                <v-spacer/>
            </v-card-title>
            <v-card-text>
                <fieldset v-if="debug">
                    <legend>State: {{state}}</legend>
                </fieldset>


                <v-container>
                    <template v-if="!$store.getters.user">
                        <v-row>
                            <!-- <v-col cols="12" sm="1"></v-col> -->
                            <v-col cols="12" lg="12">
                                <v-tabs-items v-model="view">

                                    <v-tab-item value="login">
                                        <v-card>
                                        <v-form id="loginForm" ref='loginForm' v-model="loginForm" v-on:submit.prevent='login'>
                                            <button></button>
                                            <v-text-field type="text" id="login_email" v-model="email" required :rules="formRules().validEmail" outlined validate-on-change @change="handle_remember_me" :label="'Email address'">
                                                <v-icon slot="append">
                                                    mdi-account
                                                </v-icon>
                                            </v-text-field>


                                            <v-text-field 
                                                :type="config.passwordType"  
                                                id="password"
                                                v-model="password" 
                                                outlined 
                                                :label="'Password'" 
                                                required 
                                                :rules="formRules().validLoginPassword" 
                                                validate-on-change>
                                                <v-icon slot="append" @click="togglePasswordType" v-if="config.passwordType != 'password'">
                                                    mdi-eye
                                                </v-icon>
                                                <v-icon slot="append" @click="togglePasswordType" v-else>
                                                    mdi-eye-off
                                                </v-icon>
                                            </v-text-field>
                                        </v-form>
                                        <v-container>
                                            <v-row>
                                                <v-col cols="12" lg="6" align-self="start">
                                                     <v-checkbox v-model="remember_me" @change="handle_remember_me" :label="'Remember me'"/>
                                                </v-col>

                                                <v-col cols="12" lg="6" align-self="end" style="text-align: right;">
                                                    <v-btn text link @click="service.send('forgot_password')"><str :index="'button > forgot_password'"/></v-btn>
                                                </v-col>

                                            </v-row>
                                        </v-container>
                                       
                                        
                                        
                                            <v-card-actions>
                                                <v-spacer/>
                                                <v-btn color="warning" id="login" @click="login" :disabled="loginForm===false"><str :index="'button > sign_in'"/></v-btn>
                                                <v-spacer/>
                                            </v-card-actions>
                                        </v-card>
                                    </v-tab-item>

                                    <v-tab-item value="forgot_password">
                                            <v-stepper v-model="stepper" vertical>
                                                <v-stepper-step step="1" editable>
                                                    Send a reset code to account email.
                                                </v-stepper-step>
                                                
                                                    <v-stepper-content step="1">
                                                        <v-card>
                                                            <v-card-text>
                                                                <v-form ref="forgot_password_form" v-model="valid_form_forgot_password" v-on:submit.prevent='`return false;`'>
                                                                    <v-text-field type="text" id="forgot_email" v-model="email" required :rules="formRules().validEmail" outlined validate-on-change :label="'Enter your e-mail address to help us identify you'">
                                                                        <v-icon slot="append">
                                                                            mdi-account
                                                                        </v-icon>
                                                                    </v-text-field>
                                                                </v-form>
                                                                <v-btn small color="warning" @click="service.send('forgot_password.send_code');">Send Verification Code</v-btn>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-stepper-content>


                                                
                                                <v-stepper-step step="2" :editable="(email && email.length>5)">
                                                    Verify reset code
                                                </v-stepper-step>
                                                
                                                    <v-stepper-content step="2">
                                                        <v-card>
                                                            <v-card-text>
                                                                <v-text-field type="text" id="verfication_code" :disabled="!valid_form_forgot_password" v-model="verfication_code" outlined :label="'Verification Code'">
                                                                    <v-icon slot="append">
                                                                        mdi-barcode
                                                                    </v-icon>
                                                                </v-text-field>
                                                                <v-btn small color="warning" @click="service.send('forgot_password.validate_code')">Verify Code</v-btn>      
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-stepper-content>


                                                
                                                <v-stepper-step step="3">
                                                    Reset Password
                                                </v-stepper-step>
                                                
                                                    <v-stepper-content step="3">
                                                        <v-card>
                                                            <v-card-text>
                                                                <PasswordForm :force_change="true" @update="update_password"/>
                                                                <!-- <br><br>
                                                                <v-btn :disabled="new_password===null" @click="service.send('forgot_password.change_password')">Change Password</v-btn> -->
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-stepper-content>
                                            </v-stepper>
                                    
                                        
                                        <v-btn @click="service.send('login')">Cancel</v-btn>
                                        
                                    </v-tab-item>

                                </v-tabs-items>
                            </v-col>
                            <!-- <v-col cols="12" sm="1"></v-col> -->
                        </v-row>

                        <v-row>
                            <v-col cols="12" sm="12">
                                <p v-if="context.error">
                                    <template v-if="debug">
                                        {{context.error}}
                                    </template>
                                    <template v-else>
                                        LOGIN FAILED
                                    </template>
                                </p>                                
                            </v-col>
                        </v-row>




                    </template>

                    <v-row v-else>
                        <v-col cols="12" sm="12">
                            <v-btn @click="logout"><str :index="'button > sign_out'"/></v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card-text>
            <!-- <v-card-actions>
                <v-spacer/>
                    <v-btn @click="service.send('forgot_password')">forgot_password</v-btn>
                <v-spacer/>
            </v-card-actions> -->
        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display">
        <span v-html="snackbar.message"/>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import PasswordForm from '@/components/Forms/PasswordForm.vue'

export default {
    components: {
        PasswordForm
    },
    props: {
        debug: {
            type: Boolean,
            required: false,
            default: false
        },
        show: {
            type: Boolean,
            required: true
        }
    },
    created: function(){
        this.display = this.show===true;
        if(this.cookie && this.cookie.get('email')){
            this.email = this.cookie.get('email');
            this.remember_me = true;
        }else{
            this.remember_me = false;
        }

        let component = this;

        const comms = new function(){

            this.login = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'authenticate';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            email: component.email,
                            password: component.password
                        }).then(function(response){
                            let token = response.data[action][call].jwt;
                            if(token){
                                component.$store.dispatch('jwt',token);
                                context.error = null;
                                resolve();
                            }else{
                                context.error = response.data[action][call].results;
                                reject();
                            }
                        },function(response){
                            context.error = response.data;
                            component.$store.dispatch('clear_jwt');
                            reject();
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.logout = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'logout';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call
                        }).then(function(){
                            component.$store.dispatch('clear_jwt');
                            resolve();
                        },function(response){
                            context.error = response
                            reject();
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.send_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'send_code';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            email: component.email
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve();
                            }else{
                                reject();
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.validate_code = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'validate_code';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,
                            reset_code: component.verfication_code,
                            email: component.email
                        }).then(function(response){
                            if(response.data[action][call].result===true){
                                resolve()
                            }else{
                                reject()
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            this.change_password = function(component, context){
                return new Promise((resolve, reject)=>{
                    let action = 'login';
                    let call = 'change_password';

                    if(component.sendRequest){                  
                        component.sendRequest({
                            action: action,
                            call: call,                            
                            email: component.email,
                            password: component.new_password,
                            reset_code: component.verfication_code,
                        }).then(function(response){
                            if(response.data[action][call].result){
                                resolve()
                            }else{
                                reject()
                            }
                        },function(){
                            reject()
                        })
                    }else{
                        setTimeout(function(){
                            if(context.error.result){
                                resolve();
                            }else{
                                reject()
                            }
                        },500)
                    }
                })
            }

            
        }    

        let machineConfig = {
            id: 'login',
            context: {
                error: null
            },
            initial: 'login',
            states: {
                login: {
                    invoke: {
                        src: () => new Promise((resolve)=>{
                            component.verfication_code = null;
                            component.stepper = 1;
                            resolve();
                        })
                    }
                },
                forgot_password: {
                    initial: 'ready',
                    states: {
                        ready: {
                            on: {
                                'forgot_password.send_code' : 'send_code',
                                'forgot_password.validate_code' : 'validate_code'
                            }
                        },
                        send_code: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.send_code(component, context).then(function(){
                                        component.snackbar.message = 'Validation code sent to: '+component.email;
                                        component.snackbar.display = true;
                                        component.stepper++;
                                        resolve();
                                    },function(){
                                        component.snackbar.message = 'Unknown account: '+component.email;
                                        component.snackbar.display = true;
                                        reject();
                                    })
                                }),
                                onDone: {
                                    target: 'ready'
                                }
                            }
                        },
                        validate_code: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.validate_code(component, context).then(function(){
                                        component.stepper++;
                                        resolve();
                                    },function(){
                                        component.snackbar.message = 'Incorrect code';
                                        component.snackbar.display = true;
                                        reject();
                                    })
                                }),
                                onDone: {
                                    target: 'reset_password'
                                },
                                onError: {
                                    target: 'ready'
                                }
                            }
                        },
                        reset_password: {
                            on: {
                                'forgot_password.change_password' : 'change_password'
                            }
                        },
                        change_password: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    comms.change_password(component, context).then(function(){
                                        component.password = component.new_password;
                                        component.snackbar.message = "Password change successful.  Logging in...";
                                        component.snackbar.display = true;
                                        setTimeout(function(){
                                            resolve();
                                        },3000)
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: {
                                    target: '#login.authenticate'
                                },
                                onError: {
                                    target: '#login.error'
                                }
                            }
                        }
                    }
                },
                authenticate: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            
                            comms.login(component, context).then(function(){
                                component.password = null;
                                if(component.remember_me!=true){
                                    component.email = null;
                                }
                                resolve();
                            },function(){
                                component.password = null;
                                reject();
                            })
                        }),
                        onDone: 'success',
                        onError: 'failed'
                    }
                },
                success: {
                    invoke: {
                        src: () => new Promise(()=>{
                            component.$emit('close');
                        })
                    }
                },
                failed: {},
                logout: {
                    invoke: {
                        src: (context) => new Promise((resolve, reject)=>{
                            comms.logout(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: 'login',
                        onError: 'error'
                    }
                },
                error: {},

            },
            on: {
                'authenticate': 'authenticate',
                'logout': 'logout',
                'login': 'login',
                'forgot_password': 'forgot_password.ready'
            }
        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();
    },
    data: function(){
        return {
            loginForm: false,
            valid_form_forgot_password: false,
            display: null,
            service: null,
            state: null,
            context: null,
            email: null,
            password: null,
            remember_me: null,
            verfication_code: null,
            config: {
                passwordType: 'password'
            },
            snackbar: {
                display: false,
                message: null
            },
            new_password: null,
            stepper: 1
        }
    },
    methods: {
        login: function(){
            if(this.$refs.loginForm.validate()){
                this.service.send('authenticate');
            }
        },
        logout: function(){
            this.service.send('logout');
        },
        togglePasswordType: function(){
            this.config.passwordType = this.config.passwordType=='password' ? 'text' : 'password';
        },
        handle_remember_me: function(){
            if(this.remember_me===true){
                this.cookie.set('email',this.email);
            }else{
                this.cookie.clear('email');
            }
        },
        update_password: function(data){
            this.new_password = data;
            this.service.send('forgot_password.change_password')
        }
    },
    computed: {
        view: function(){
            if(this.state.forgot_password){
                return 'forgot_password'
            }else{
                return 'login'
            }
        }
    },
    watch: {
        show: function(){
            this.display = this.show===true;
        }
    }
}
</script>

<style>

</style>