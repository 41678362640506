<template>
<v-form ref="userForm" v-model="validForm">
    <v-container>
        <v-row>
            <v-col cols="12" lg="4" v-if="show.firstname">
                <v-text-field 
                    v-model="form.firstname" 
                    outlined 
                    :label="'First Name'"
                    required 
                    :rules="formRules().validFirstName" 
                    validate-on-change/>
            </v-col>
            <v-col cols="12" lg="4" v-if="show.lastname">
                <v-text-field 
                    v-model="form.lastname" 
                    outlined 
                    :label="'Last Name'"
                    required 
                    :rules="formRules().validLastName" 
                    validate-on-change/>
            </v-col>
            <v-col cols="12" lg="4" v-if="show.email">
                <EmailForm :email="form.email" :admin="admin" @update="update_field('email',$event)" @state_change='state_change_email'/>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="4" v-if="show.specialty">
                <v-autocomplete v-model="form.specialty" label="Specailty" 
                    :items="specialities" 
                    :rules="formRules().validSpecialty"
                    background-color="white"
                    dense
                    outlined>
                    <template v-slot:message="{ message }">
                        <str :index="message" />
                    </template>
                    <template slot="selection" slot-scope="data" dense>
                        <!-- HTML that describe how select should render selected items -->
                        <str :index="data.item.text" />
                    </template>

                    <v-list-item-content slot="item" slot-scope="data" dense>
                        <!-- HTML that describe how select should render items when the select is open -->
                        <v-list-item-title><str :index="data.item.text" /></v-list-item-title>
                    </v-list-item-content>    
                </v-autocomplete>
            </v-col>
            <v-col cols="12" lg="4" v-if="show.language">
                <v-select outlined v-model="form.language" label="Preferred Language" :items="languages"/>
            </v-col>
            <v-col cols="12" lg="4" v-if="show.password">
                <PasswordForm :admin="admin" @update="update_field('password',$event)" @state_change='state_change_password' :force_change="options && options.password ? options.password.force_change : false"/>
            </v-col>
        </v-row>
    </v-container>
</v-form>
</template>

<script>
import EmailForm from '@/components/Forms/EmailForm.vue'
import PasswordForm from '@/components/Forms/PasswordForm.vue'
import SpecialtyList from './SpecialtyList.js';

export default {
    components: {
        EmailForm,
        PasswordForm
    },
    props: {
        user: {
            type: Object,
            required: true
        },
        admin: {
            type: Boolean,
            required: false,
            default: false
        },
        options: {
            type: Object,
            required: false
        }
    },
    created: function(){
        // let user = this.unlink(this.user);
        this.form = this.unlink(this.user)
    },
    data: function(){
        return {
            fields: [
                'firstname',
                'lastname',
                'email',
                'city',
                'province',
                'language',
                'specialty',
                'profession',
                'password',
            ],
            form: null,
            validForm: false,
            component_state: {
                email: 'idle',
                password: 'idle'
            }
        }
    },
    methods: {
        update: function(){
            this.$emit('update',this.form);
        },
        state_change_email: function(data){
            this.component_state['email'] = data;
        },
        state_change_password: function(data){
            this.component_state['password'] = data;
        },
        update_field: function(field, data){
            this.form[field] = data;
            this.update();
        },
        change_password: function(data){
            this.$emit('change_password',data)
        }
    },
    computed: {
        languages: function(){
            let languages = [];

            for(let language in this.$store.getters.library.languages){
                languages.push(language);
            }

            return languages;
        },
        specialities: function(){
            return SpecialtyList;
        },
        show: function(){
            let fields = this.fields;
            let options = this.options;
            let in_array = this.in_array;
            let show = {};

            for(let i=0; i<fields.length; i++){
                let field = fields[i];
                show[field] = !options || in_array(field,options.show)
            }
            return show
        },
        validUserForm: function(){
            let component_state = this.component_state
            let pass = true;
            for(let key in component_state){
                if(component_state[key]!='idle'){
                    pass = false;
                    break;
                }
            }
            return this.validForm && pass;
        }
    },
    watch: {
        form: {
            deep: true,
            handler: function(){
                this.update()
            }
        },
        validUserForm: function(){
            this.$emit('valid',this.validUserForm)
        }
    }
}
</script>

<style>

</style>