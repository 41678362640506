import Vue from 'vue'
import Vuex from 'vuex'
import cookie from '@/plugins/cookie'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    api: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/src/api/requestHandler.php" : "/src/api/requestHandler.php",
    download_handler: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/src/api/downloadHandler.php" : "/src/api/downloadHandler.php",
    upload_handler: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/src/api/uploadHandler.php" : "/src/api/uploadHandler.php",
    baseURL: process.env.NODE_ENV == 'development' ? "http://"+window.location.hostname+"/" : "https://"+window.location.hostname + "/",
    csrf: null,
    jwt: null,
    user: null,
    language: 'en',
    library: null,
    windowSize: {
      x: 0,
      y: 0
    },
    entryURL: null,
    support: null,
    programs: null
  },
  getters: {
    api: function(state){
      return state.api
    },
    download_handler: function(state){
      return state.download_handler;
    },
    upload_handler: function(state){
      return state.upload_handler;
    },
    language: function(state){
      return state.language
    },
    library: function(state){
      return state.library
    },
    csrf: function(state){
      return state.csrf
    },
    jwt: function(state){
      return state.jwt
    },
    user: function(state){
      return state.user
    },
    baseURL: function(state){
      return state.baseURL;
    },
    windowSize: function(state) {
      return state.windowSize;
    },
    entryURL: function(state){
      return state.entryURL;
    },
    programs: function(state){
      return state.programs;
    }
  },
  actions: {
    language: function(context, payload){
      context.commit('language', payload);
    },
    library: function(context, payload){
      context.commit('library',payload);
    },
    csrf: function(context, payload){
      cookie.set('csrf', payload);
      context.commit('csrf', payload);
    },
    clear_csrf: function(context){
        cookie.clear('csrf');
        context.commit('csrf', null);
    },
    jwt: function(context, payload){
      cookie.set('jwt', payload);
      context.commit('jwt', payload);
    },
    clear_jwt: function(context){
        cookie.clear('jwt');
        context.commit('jwt', null);
        context.commit('user',null);
    },
    user: function(context, payload){
      context.commit('user', payload);
    },
    windowSize: function(context, payload) {
      context.commit('windowSize', payload);
    },
    entryURL: function(context, payload){
      context.commit('entryURL', payload);
    },
    programs: function(context, payload){
      context.commit('programs', payload);
    }
  },
  mutations: {
    language: function(state , payload){
      state.language = payload
    },
    library: function(state , payload){
        state.library = payload
    },
    csrf: function(state , payload){
      state.csrf = payload
    },
    jwt: function(state , payload){
        state.jwt = payload;
        var jwt = payload;
        if(jwt){
            var token = parseJWT(jwt);
            this.dispatch('user',parseJSON(token.user));
        }
    
        function parseJSON(object){
          for(let key in object){
            var item = object[key];
            if(typeof item=='string' && item.length>=2){
              try{
                item = JSON.parse(item);
                object[key] = item;
              }catch(e){
                var garbage = e;
                garbage = null;
                if(garbage!=null){
                  console.log('garbage',garbage);
                }
              }
            }
          }
          return object;
        }

        function parseJWT (token) {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            return JSON.parse(window.atob(base64));
        } 
    },
    user: function(state , payload){
      state.user = payload
    },
    windowSize: function(state, payload) {
      state.windowSize.x = payload.x;
      state.windowSize.y = payload.y;
    },
    entryURL: function(state , payload){
      state.entryURL = payload
    },
    programs: function(state , payload){
      state.programs = payload
    }
  },
  modules: {
  }
})
