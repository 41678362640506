<template>
<div>
    <h3 align='center'>
        <str :index="'editorial_board > title'"/>
    </h3>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4">
                <Member :member="$Member_1" style="display: inline-block;"/>
            </v-col>

            <v-col cols="12" lg="4">
                <Member :member="$Member_2" style="display: inline-block;"/>
            </v-col>

            <v-col cols="12" lg="4">
                <Member :member="$Member_3" style="display: inline-block;"/>
            </v-col>

        </v-row>
    </v-container>
</div>
</template>

<script>
import BoardMember_1 from './assets/img_1.png';
import BoardMember_2 from './assets/img_2.png';
import BoardMember_3 from './assets/img_3.png';
import Member from './member.vue'

export default {
    components: {
        Member
    },
    computed: {
        $Member_1: function(){
            return {
                name: "Member 1",
                credentials: "MD, PhD, ABC",
                image: BoardMember_1
            }
        },
        $Member_2: function(){
            return {
                name: "Member 2",
                credentials: "MD, PhD, ABC",
                image: BoardMember_2
            }
        },
        $Member_3: function(){
            return {
                name: "Member 3",
                credentials: "MD, PhD, ABC",
                image: BoardMember_3
            }
        }
    }
}
</script>

<style>

</style>