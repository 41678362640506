<template>
<div>
    <video id='videoPlayer' style='width:100%; margin: 0 auto;' controls controlsList="nodownload" autoplay>
        <source :src="source" type="video/mp4">
    Your browser does not support the video tag.
    </video>
</div>
</template>

<script>
export default {
    props: {
        video: {
            type: Object,
            required: true
        }
    },
    mounted: function(){
        let videoPlayer = document.getElementById('videoPlayer');
        videoPlayer.addEventListener('contextmenu', event => event.preventDefault());
    },
    beforeDestory: function(){
        this.player.stop();
    },
    computed: {
        source: function(){
            let host = this.$store.getters.api;
            let video = this.video;
            let params = '';
            let counter = 0;
            for(let key in video){
                if(counter==0){
                    params+="?";
                }else{
                    params+="&"
                }
                params+=key+"="+video[key];
                counter ++;
            }
            return host+params+'&token='+this.$store.getters.csrf;
        },
        player: function(){
            return document.getElementById('videoPlayer');
        }
    }
}
</script>

<style>

</style>