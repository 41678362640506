<template>
<div>
    <v-dialog v-model="display" max-width="1024" persistent>
        <v-card>
            <v-card-title>
                <v-spacer/>
                    <ContactIcon style="margin:15px;"/>
                    <LanguageSelect style="width:50px;"/>
                <v-spacer/>
            </v-card-title>

            <v-card-text :class="$style.content">

                <div :class="$style.header">
                    <str :index="'login > title'"/>
                </div>

                Please verify your information below
                <template v-if="state=='verify'">
                    <v-form ref="verifyForm" v-model="validVerifyForm">
                        <v-text-field type="text" outlined v-model="form.firstname" label="firstname"/>
                        <v-text-field type="text" outlined v-model="form.lastname" label="lastname"/>
                        <v-text-field type="email" outlined v-model="form.email" label="email"/>
                        <v-btn color="warning" @click="verify">Verify</v-btn>
                    </v-form>
                </template>

                <template v-if="state.activate && state.activate.start">
                    <UserForm v-if="allow" :user="account" @update="update_form" @valid="setActivateFormValid" :options="{show:['firstname','lastname','email','password','language'],password:{force_change:true}}"/>
                    <v-btn :disabled="!allow_activate" @click="activate_account" color="warning">Activate Your Account</v-btn>
                </template>

                <template v-if="state.activate=='done'">
                    THIS ACCOUNT IS ACTIVE
                </template>

            </v-card-text>


        </v-card>
    </v-dialog>

    <v-snackbar v-model="snackbar.display">
        <span v-if="snackbar.message">
            <str :index="snackbar.message"/>
        </span>
    </v-snackbar>
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond
import UserForm from '@/components/Forms/UserForm.vue'
import ContactIcon from '@/components/UI/ContactIcon.vue'
import LanguageSelect from '@/components/UI/LanguageSelect.vue'

export default {
    components: {
        UserForm,
        ContactIcon,
        LanguageSelect
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){
            this.fetch = new function(){

                this.user = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'activate_account';
                        let call = 'fetch_user';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                data: component.method=='id' ? component.params : component.form,
                                method: component.method
                            }).then(function(response){
                                let output = response.data[action][call];
                                context.error.dataHandler = null;
                                if(output.results){
                                    component.account = component.parseJSON(output.user);
                                    resolve();
                                }else{
                                    context.error.dataHandler = output.result;
                                    reject();
                                }
                            },function(response){
                            context.error.dataHandler = response;
                            reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }

            this.send = new function(){

                this.activate_account = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'activate_account';
                        let call = 'activate_account';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call,
                                data: component.form
                            }).then(function(response){
                                let output = response.data[action][call];
                                context.error.dataHandler = null;
                                if(output.result){
                                    resolve();
                                }else{
                                    context.error.dataHandler = output.result;
                                    reject();
                                }
                            },function(response){
                            context.error.dataHandler = response;
                            reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }
        }    

        let machineConfig = {
            id: 'app',
            context: {
            error: {
                app: null,
                dataHandler: null
            }
            },
            initial: 'idle',
            states: {
                error: {
                    invoke: {
                        src: () => new Promise(()=>{
                            component.snackbar.message = 'activate_account > error > invalid_link';
                            component.snackbar.display = true;
                            setTimeout(function(){
                                component.$router.push('/login')
                            },3000)
                        })
                    }
                },
                idle: {
                    on: {
                        'verify': '#app.verify',
                        'search': '#app.search'
                    }                    
                },
                verify: {
                    on: {
                        'search': '#app.search'
                    }    
                },
                activate: {
                    id: 'activate',
                    initial: 'idle',
                    states: {
                        idle: {},
                        start: {
                            type: 'parallel',
                            invoke: {
                                src: () => new Promise((resolve,reject)=>{
                                    if(component.account.validated=='N'){
                                        component.$store.dispatch('language',component.account.language);
                                        resolve();
                                    }else{
                                        reject();
                                    }
                                }),
                                onError: {
                                    target: '#activate.done'
                                }
                            },
                            states: {
                                change_email: {
                                    id: 'change_email',
                                    initial: 'inactive',
                                    states: {
                                        inactive: {},
                                        active: {}
                                    }
                                },
                                change_password: {
                                    id: 'change_password',
                                    initial: 'inactive',
                                    states: {
                                        inactive: {},
                                        active: {}
                                    }
                                }
                            }
                        },                
                        done: {
                            invoke: {
                                src: () => new Promise(()=>{
                                    setTimeout(function(){
                                        component.$router.push('/login')
                                    },3000)
                                })
                            }
                        }
                    }

                },
                search: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            dataHandler.fetch.user(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: '#activate.start'
                        },
                        onError: {
                            target: '#app.error'
                        }
                    }
                },
                activate_account: {
                    invoke: {
                        src: (context) => new Promise((resolve,reject)=>{
                            dataHandler.send.activate_account(component, context).then(function(){
                                resolve();
                            },function(){
                                reject();
                            })
                        }),
                        onDone: {
                            target: '#activate.done'
                        },
                        onError: {
                            target: '#app.error'
                        }
                    }
                }
            },
            on: {
                'change_email.active' : '#change_email.active',
                'change_email.inactive' : '#change_email.inactive',
                'change_password.active' : '#change_password.active',
                'change_password.inactive' : '#change_password.inactive',
                'activate_account' : '#app.activate_account'
            }

        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();
        

        switch(this.$route.name){
            case"Verify":
                this.service.send('verify');
            break;

            case"Activate":
                this.service.send('search');
            break;
        }
    },
    data: function(){
        return {
            display: true,
            state: null,
            service: null,
            context: null,
            account: null,
            validVerifyForm: false,
            validActivateForm: false,
            form: {
                id: null,
                email: null,
                firstname: null,
                lastname: null,
                password: null
            },
            snackbar: {
                display: false,
                message: null
            }
        }
    },
    methods: {
        setActivateFormValid: function(data){
            this.validActivateForm = data;
        },
        verify: function(){
            this.service.send("search");
        },
        update_form: function(data){
            let form = this.form;
            for(let key in form){
                form[key] = data[key]
            }
        },
        change_email: function(data){
            if(data){
                this.service.send('change_email.active')
            }else{
                this.service.send('change_email.inactive')
            }
        },
        change_password: function(data){
            if(data){
                this.service.send('change_password.active')
            }else{
                this.service.send('change_password.inactive')
            }
        },
        activate_account: function(){
            this.service.send('activate_account')
        }
    },
    computed: {
        method: function(){
            return this.$route.name=='Verify' ? 'email' : 'id';
        },
        params: function(){
            return this.$route.params;
        },
        allow: function(){
            let account = this.account;
            let params = this.params;
            let form = this.form;
            let method = this.method;

            if(account){
                if(method=="id"){
                    return account.validation_code == params.validation_code;
                }else if(method=="email"){
                    return account.email == form.email;
                }else{
                    return false;
                }
            }else{
                return false;
            }
        },
        allow_activate: function(){
            let form = this.form;
            let firstname = form.firstname && form.firstname.length>0;
            let lastname = form.lastname && form.lastname.length>0;
            let password = form.password && form.password.length>0;
            let state = this.state;
            return firstname && lastname && password && state.activate.start.change_email=='inactive';
        }
    },
    watch: {
        state: function(){
            let state = this.state;
            if(state=='activate'){
                if(this.allow && this.account.active=='Y'){
                    this.service.send('activated')
                }
            }
        }
    }
}
</script>

<style module>
.header{
    width: 100%;
    text-align: center;
    color: #1C75BC;
}

.content{
    width: 80%;
    margin: 0 auto;
}
</style>