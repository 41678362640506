<template>
<div>
    <h3>Data Dashboard</h3>
    <v-container>
        <v-row>
            <v-col cols="12" lg="4">
                <v-card>
                    <v-card-title># of logins</v-card-title>
                    <v-card-text>
                        {{$first_logins}} / {{$invitations_send}} <span style="font-size: 9pt">(invitations sent)</span>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4">
                <v-card>
                    <v-card-title># of PPT Downloads</v-card-title>
                    <v-card-text>
                        {{$ppt_downloads}} Unique
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col cols="12" lg="4">
                <v-card>
                    <v-card-title># of Videos Watched</v-card-title>
                    <v-card-text>
                        {{$video_views}} Unique
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>

    <v-data-table :headers="headers" :items="user_records"
        :loading-text="'...loading...'"
        :hide-default-footer="true"
        :disable-pagination="true"
        :footer-props="{
            itemsPerPageOptions: [50,100,200,-1]
        }"
        :multi-sort="true"
        class="elevation-1 tracker_table"
        dense     
    />
</div>
</template>

<script>
import { Machine, interpret} from 'xstate'; //assign, sendParent, spawn, raise, actions, send, respond

export default {
    props: {
        programs: {
            type: Array,
            required: true
        }
    },
    created: function(){
        let component = this;

        const dataHandler = new function(){
            this.fetch = new function(){

                this.users = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_users';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let users = response.data[action][call].results;
                                for(let i=0; i<users.length; i++){
                                    users[i] = component.parseJSON(users[i]);
                                }
                                
                                component.users = users;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.email_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_activation_email_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.emails = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.download_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_download_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.downloads = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.view_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_view_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call].results;
                                for(let i=0; i<logs.length; i++){
                                    logs[i] = component.parseJSON(logs[i]);
                                }
                                
                                component.logs.views = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }

                this.login_logs = function(component, context){
                    return new Promise((resolve, reject)=>{
                        let action = 'admin';
                        let call = 'fetch_login_logs';

                        if(component.sendRequest){
                            component.sendRequest({
                                action: action,
                                call: call
                            }).then(function(response){
                                let logs = response.data[action][call];
                                
                                component.logs.logins = logs;
                                context.error.dataHandler = null;
                                resolve();
                            },function(response){
                                context.error.dataHandler = response;
                                reject();
                            })
                        }else{
                            setTimeout(function(){
                                resolve()
                            },1000)
                        }
                    })
                }
            }
        }
        component.dataHandler = dataHandler

        let machineConfig = {
            id: 'admin',
            context: {
                error: {
                dataHandler: null
                }
            },
            initial: 'idle',
            states: {
                idle: {
                    always: '#fetch.users'
                },
                ready: {
                    on: {
                        'reload' : '#admin.idle'
                    }
                },
                fetch: {
                    id: "fetch",
                    initial: 'idle',
                    states: {
                        idle: {},
                        users: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    dataHandler.fetch.users(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#fetch.logs',
                                onError: '#admin.error'
                            }
                        },
                        logs: {
                            invoke: {
                                src: (context) => new Promise((resolve,reject)=>{
                                    let promises = [];
                                    let email_logs = dataHandler.fetch.email_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let login_logs = dataHandler.fetch.login_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let download_logs = dataHandler.fetch.download_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                    let view_logs =  dataHandler.fetch.view_logs(component, context).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })

                                    promises.push(email_logs);
                                    promises.push(login_logs);
                                    promises.push(download_logs);
                                    promises.push(view_logs);

                                    Promise.all(promises).then(function(){
                                        resolve()
                                    },function(){
                                        reject()
                                    })
                                }),
                                onDone: '#admin.ready',
                                onError: '#admin.error'
                            }
                        }
                    }
                },
                error: {}
            }

        }

        const machine = Machine(machineConfig,{
            guards: {
                allow_comms: function(context){
                    return (component && component.$store) ? component.$store.getters.csrf!=null : context.csrf.value!=null;
                },
                allow_login: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt===null : context.jwt.value===null;
                },
                allow_logout: function(context){
                    return (component && component.$store) ? component.$store.getters.jwt!=null : context.jwt.value!=null;
                }
            }
        });


        this.service = interpret(machine)
        this.state = machine.initialState
        this.context = machine.context

        var self = this
        self.service.onTransition(state => {
            self.state = state.value;
            self.context = state.context
        }).start();

    },
    data: function(){
        return {
            state: null,
            context: null,
            service: null,
            users: null,
            logs: {
                emails: null,
                downloads: null,
                views: null,
                logins: null
            }
        }
    },
    computed: {
        $invitations_send: function(){
            return this.logs.emails ? this.objectLength(this.logs.emails) : 0;
        },
        $first_logins: function(){
            return this.logs.logins ? this.objectLength(this.logs.logins.first_logins) : 0;
        },
        maps: function(){
            let maps = {
                last_logins: {},
                first_logins: {},
                logins: {}
            }

            let first_logins = this.logs.logins ? this.logs.logins.first_logins : [];
            for(let i=0; i<first_logins.length; i++){
                let record = first_logins[i];
                maps.first_logins[record.user_id] = record;
                maps.logins[record.user_id] = record.logins;
            }

            let last_logins = this.logs.logins ? this.logs.logins.last_logins : [];
            for(let i=0; i<last_logins.length; i++){
                let record = last_logins[i];
                maps.last_logins[record.user_id] = record;
            }

            return maps;
        },
        headers: function(){
            let headers = [
                {text:'name', value:'name'},
                {text:'Validation Date', value:'validation_date'},
                {text:'Last Login', value:'last_login'},
                {text:'Login Count', value:'login_count'},
            ];


            return headers;
        },
        user_records: function(){
            let user_records = [];
            let users = this.users;

            if(users){
                for(let i=0; i<users.length; i++){
                    let user = users[i];
                    user_records.push({
                        name: user.firstname + ' ' + user.lastname,
                        validation_date: user.validation_timestamp,
                        last_login: this.maps.last_logins[user.id] ? this.maps.last_logins[user.id].timestamp : null,
                        login_count: this.maps.logins[user.id]
                    })
                }

            }

            return user_records;
        },
        $ppt_counter: function(){
            let programs = this.programs;
            let counter = {}
            let count_filter = {}

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                counter[program.id] = {};
                count_filter[program.id] = {};

                for(let t=0; t<program.topics.length; t++){
                    let topic = program.topics[t];
                    counter[program.id][t] = {}
                    count_filter[program.id][t] = {}

                    for(let language in topic.files.ppt){
                        counter[program.id][t][language] = 0
                        count_filter[program.id][t][language] = []
                    }
                }
            }

            let downloads = this.logs.downloads;
            if(downloads){
                for(let i=0; i<downloads.length; i++){
                    let record = downloads[i];
                    let params = record.parameters;
                    if(!this.in_array(record.user_id,count_filter[params.program_id][params.topic_index][params.language])){
                        counter[params.program_id][params.topic_index][params.language]++;
                        count_filter[params.program_id][params.topic_index][params.language].push(record.user_id);
                    }
                }
            }

            return counter;
        },
        $ppt_downloads: function(){
            let ppt_counter = this.$ppt_counter;
            let total=0;
            for(let program_id in ppt_counter){
                let program = ppt_counter[program_id];
                for(let t in program){
                    let downloads = program[t];
                    for(let language in downloads){
                        let count = downloads[language];
                        total+=count;
                    }
                }
            }

            return total;
        },
        $video_counter: function(){
            let programs = this.programs;
            let counter = {}
            let count_filter = {}

            for(let i=0; i<programs.length; i++){
                let program = programs[i];
                counter[program.id] = {};
                count_filter[program.id] = {};

                for(let t=0; t<program.topics.length; t++){
                    let topic = program.topics[t];
                    counter[program.id][t] = {}
                    count_filter[program.id][t] = {}

                    for(let language in topic.files.video){
                        counter[program.id][t][language] = 0
                        count_filter[program.id][t][language] = []
                    }
                }
            }

            let views = this.logs.views;
            if(views){
                for(let i=0; i<views.length; i++){
                    let record = views[i];
                    let params = record.parameters;
                    if(!this.in_array(record.user_id,count_filter[params.program_id][params.topic_index][params.language])){
                        counter[params.program_id][params.topic_index][params.language]++;
                        count_filter[params.program_id][params.topic_index][params.language].push(record.user_id);
                    }
                }
            }

            return counter;
        },
        $video_views: function(){
            let video_counter = this.$video_counter;
            let total=0;
            for(let program_id in video_counter){
                let program = video_counter[program_id];
                for(let t in program){
                    let views = program[t];
                    for(let language in views){
                        let count = views[language];
                        total+=count;
                    }
                }
            }

            return total;
        }
    }
}
</script>

<style>

</style>