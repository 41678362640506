<template>
    <v-select :class="$style.language" outlined v-model="language" :items="$languages" @change="dispatch"/>
</template>

<script>
export default {
    created: function(){
        this.language = this.$store.getters.language;
        let cookie_language = this.cookie.get('language');
        if(cookie_language){
            this.language = cookie_language;
            this.dispatch();
        }
        
    },
    data: function(){
        return {
            language: null
        }
    },
    methods: {
        dispatch: function(){
            this.$store.dispatch('language',this.language);
            this.cookie.set('language',this.language);
            this.$emit('language',this.language);
        }
    },
    computed: {
        $languages: function(){
            let options = [];
            for(let language in this.$store.getters.library.languages){
                options.push({
                    text: language,
                    value: language
                })
            }
            return options;
        },
        current_language: function(){
            return this.$store.getters.language;
        }
    },
    watch: {
        current_language: function(){
            if(this.current_language!=this.language){
                this.language=this.current_language;
            }
        }
    }
}
</script>

<style module>
.language{
    position: relative;
    top: 15px;
}

.language fieldset{
    background-color: #1D75BC;
}
</style>