<template>
<div style="text-align: center;">
    
    <input v-show="false" type="file" @change="onFileSelect" :ref="'upload_input'" :name="config.name"  :accept="config.accept" :multiple="config.multiple ? config.multiple : false" required/>
    <v-btn @click="$refs['upload_input'].click()" dark color="#1ea1bd">
        <v-icon left small>mdi-file</v-icon>
        SELECT FILE(S)
    </v-btn>

    <ol v-if="files.length>0" style="margin: 15px; font-size: 10pt;">
        <li v-for="(file, index) in files" :key="'uploading_'+index" style="margin-bottom: 5px;text-align: center;">
            <strong>{{file.name}}</strong> 
            <hr> 
            <sup v-if="state=='idle'" style="font-size: 0.9rm">waiting to upload</sup>
            <v-progress-linear v-if="state=='uploading'" :value="progress[index]"></v-progress-linear>
        </li>
    </ol>

    <v-btn v-if="(files.length>0 && state=='idle')" small color="green" @click="upload">
        <v-icon small left>mdi-upload</v-icon> Upload
    </v-btn>

    <v-snackbar v-model="snackbar.display" :multi-line="true">
        <ul>
            <li v-for="(message, index) in snackbar.messages" :key="'snackbar_message_'+index">
                {{message}}
            </li>
        </ul>


        <template v-slot:action="{ attrs }">
            <v-btn
            color="red"
            text
            v-bind="attrs"
            @click="snackbar.display = false"
            >
            Close
            </v-btn>
        </template>        
    </v-snackbar>
</div>  
</template>

<script>
import axios from 'axios'

export default {
    name: 'fileUpload',
    props: {
        config: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: false,
            default: 'en'
        },
        action: {
            type: String,
            required: true
        },
        call: {
            type: String,
            required: true
        },
        folder: {
            type: String,
            required: true
        }
    },
    data: function(){
        return {
            files: [],
            progress: [],
            state: 'idle',
            snackbar: {
                display: false,
                messages: []
            },
            max_filesize: 800
        }
    },
    methods: {
        onFileSelect(event) {
            var target = event.target;
            let files = target.files;
            let max_filesize = this.max_filesize;
            
            for(let i=0; i<files.length; i++){
                let file = files[i];
                var filesize = ((file.size/1024)/1024).toFixed(4);
                if(filesize<=max_filesize){
                    this.files.push(file);
                }else{
                    this.snackbar.messages.push("Max filesize of "+max_filesize+"M - please contact admin for manual upload");
                    this.snackbar.display = true;
                }
            }
        },
        upload: function(){
            let self = this;
            this.state = 'uploading';
            let files = this.files;
            let promises = [];
            for(let i=0; i<files.length; i++){
                let file = files[i];
                promises.push(this.uploadFile(i, file));
            }
            Promise.all(promises).then(function(){
                self.state='idle';
                self.$emit('uploaded',{config:self.config,files:self.files});
                self.files = [];
                self.progress = [];
            })
        },
        uploadFile(index, file) {
            return new Promise((resolve,reject)=>{
                let self = this;
                axios.defaults.headers.post['X-CSRF-Token'] = self.$store.getters.csrf;
                axios.defaults.headers.post['jwt'] = self.$store.getters.jwt;
    
                const data = new FormData();
                data.append('folder',this.folder);
                data.append('filename',file.name);
                data.append('language',this.language);
                data.append('action',this.action);
                data.append('call',this.call);
                data.append("file",file);
    
                axios.post(self.$store.getters.upload_handler+'?action=uploadFile',data,{
                    onUploadProgress: uploadEvent => {
                        self.progress[index] = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                        self.$forceUpdate();
                    }
                }).then(function(){
                    self.snackbar.display = true;
                    self.snackbar.messages.push('Uploaded file# '+index+1+': '+file.name);
                    resolve();
                },function(response){
                    console.error('upload error',{
                        file: file.name,
                        response: response
                    });
                    reject();
                })
            });

        },                 
    }
}
</script>

<style module>
.previewImage{
    display: block;
    margin: 15px auto;
}
</style>