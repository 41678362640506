<template>
<div>
    <v-btn
        fab
        small
        color="white"
        elevation="0"
        rounded
        style="color: #1C75BC; border: 1px solid #1C75BC !important; text-transform: uppercase;"
        @click="trigger=!trigger"
        >
        <v-icon>mdi-help-circle</v-icon>
    </v-btn>

    <ContactUs :trigger="trigger" @close="trigger=false;"/>
</div>
</template>

<script>
import ContactUs from '@/components/ContactUs/ContactUs.vue'

export default {
    components: {
        ContactUs
    },
    data: function(){
        return {
            trigger: false
        }
    }
}
</script>

<style>

</style>