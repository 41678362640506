<template>
<div :class="$style.topics">

    <div :class="$style.header">
        <div :class="$style.title">
            <str :index="'topics > title'"/>
        </div>

        <div :class="$style.sub_title">
            <str :index="'topics > sub_title'"/>
        </div>

        <div :class="$style.legend">
            <div :class="$style.legend_item">
                <str :index="'topics > legend > ppt'"/>
                <v-icon>mdi-eye</v-icon>
            </div>
            <div :class="$style.legend_item">
                <str :index="'topics > legend > ppt > download'"/>
                <v-icon>mdi-cloud-download</v-icon>
            </div>
            <div :class="$style.legend_item">
                <str :index="'topics > legend > video'"/>
                <v-icon>mdi-play-box-outline</v-icon>
            </div>
        </div>

        <str :index="'topics > legend > note'"/>
    </div>

    <v-container>
        <v-row>
            <v-col v-for="(topic, topic_index) in program.topics" :key="'pid_'+program.id+'_'+topic_index" cols="12" lg="6" md="12">
                <table :class="$style.topic_item">
                    <tbody>
                        <tr>
                            <td>
                                {{topic.name[language]}}
                            </td>
                            <td>
                                <v-btn v-if="topic.files.preview[language]" @click="preview(program.id, topic_index, 'preview', language, topic.files.preview[language])" text>
                                    <v-icon>mdi-eye</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn v-if="topic.files.ppt[language]" @click="download(program.id, topic_index, 'ppt', language, topic.files.ppt[language])" text>
                                    <v-icon>mdi-cloud-download</v-icon>
                                </v-btn>
                            </td>
                            <td>
                                <v-btn v-if="topic.files.video[language]" @click="play(program.id, topic_index, 'video', language, topic.files.video[language])" text>
                                    <v-icon>mdi-play-box-outline</v-icon>
                                </v-btn>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </v-col>
        </v-row>
    </v-container>

    <v-dialog v-model="popups.preview">
        <v-card>
            <v-card-title>Preview</v-card-title>
            <v-card-text>
                <vueper-slides v-if="popups.preview && slides" :bullets="true">
                    <vueper-slide v-for="(slide, i) in slides" :key="'preview_'+i" :image="slide" style="background-size:container;"/>
                </vueper-slides>
            </v-card-text>
        </v-card>
    </v-dialog>

    <v-dialog v-model="popups.video" fullscreen>
        <v-card>
            <v-card-title>
                <div style="position: absolute; top: 15px; right: 15px; color: black; cursor: pointer; z-index: 1000;" @click="popups.video=false"><v-icon>mdi-close-circle-outline</v-icon></div>                
            </v-card-title>
            <v-card-text>
                <div style="text-align: center;width: 100%; position: relative;">
                    <VideoPlayer v-if="popups.video" :video="video"/>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>


</div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer/VideoPlayer.vue'
import { VueperSlides, VueperSlide } from 'vueperslides'
import 'vueperslides/dist/vueperslides.css'

export default {
    props: {
        language: {
            type: String,
            required: true
        },
        program: {
            type: Object,
            required: true
        }
    },
    components: {
        VideoPlayer,
        VueperSlides,
        VueperSlide
    },
    data: function(){
        return {
            popups: {
                preview: false,
                video: false
            },
            slides: null,
            video: null
        }
    },
    methods: {
        play: function(program_id, topic_index, type, language, files){
            let self = this;
            self.video = {
                language: language,
                pid: program_id,
                uid: self.$store.getters.user.id,
                tin: topic_index,
                video: files[0]
            }
        
            self.popups.video = true;
        },
        preview: function(program_id, topic_index, type, language, files){
            let self = this;
            self.slides = [];

            let promises = [];

            for(let f=0; f<files.length; f++){
                let file = files[f];

                promises.push(new Promise((resolve, reject)=>{
                    self.streamFile({
                        params: {
                            program_id: program_id,
                            topic_index: topic_index,
                            language: language,
                            filename: file,
                            type: type,
                            request: 'serve_file',
                        },
                        filename: file
                    }).then(function(response){
                        self.slides.push(response);
                        resolve();
                    },function(){
                        reject();
                    })
                }))
            }

            Promise.all(promises).then(function(){
                self.popups.preview = true;
            })
            
        },
        download: function(program_id, topic_index, type, language, files){
            let self = this;
            
            self.downloadFile({
                params: {
                    program_id: program_id,
                    topic_index: topic_index,
                    language: language,
                    filename: files[0],
                    type: type,
                    request: 'get_file',
                },
                filename: files[0]
            });
            
        }
    }
}
</script>

<style module>
.topics {
    padding-top: 50px;
}

.header{
    width: 100%;
    text-align: center;
    margin-bottom: 25px;
}

.title{
    color: #F49041;
    font-size: 1.5em;
    font-weight: bold;
    margin: 10px;
}

.sub_title{

}

.legend_item{
    display: inline-block;
    margin: 15px;
}

    .legend_item i{
        margin: 5px;
    }

.topic_item{
    width:100%; 
    border-bottom: 1px solid grey;
    padding: 25px;
    font-weight: bold;
    height: 91px;
}

.topics table td{
    width: 10%;
    text-align: center;
}
.topics table td:first-child{
    width: 70%;
    text-align: left;
}
</style>

<style type="text/css">
.vueperslide{
    background-size: contain !important;
}
.vueperslides__bullet .default {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
}

.vueperslides__bullet--active .default {background-color: #42b983;}

.vueperslides__bullet span {
  display: block !important;
  position: relative;
  top: -2px;
  color: #fff;
  font-size: 10px;
  opacity: 0.8;
}
</style>