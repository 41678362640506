<template>
<v-card width="100%">
    <v-img :src="member.image" contain height="200"/>
    <v-card-title>
        {{member.name}}
    </v-card-title>
    
    <v-card-subtitle>
        {{member.credentials}}
    </v-card-subtitle>
</v-card>
</template>

<script>

export default {
    props: {
        member: {
            type: Object,
            required: true
        }
    }
}
</script>

<style>

</style>